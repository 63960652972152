import { Col, Container, PageBanner, Pagination, Row, SearchInput, SocialButton, Text, Thumbnail, Title, usePagination, useSearch } from '@zigurous/react-components'; // prettier-ignore
import { Link as GatsbyLink, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { ImageProps, Page } from '../components';
import { trackEvent } from '../firebase/analytics';

export const query = graphql`
  query Tutorials {
    json: allTutorialsJson {
      tutorials: nodes {
        id: jsonId
        title
        subtitle
        searchQuery
        image {
          sharp: childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;

const metadata = {
  url: 'https://zigurous.com/tutorials',
  title: 'Zigurous • Tutorials',
  description:
    'Zigurous creates video tutorials to educate and inspire other developers who are interested in learning about game development.',
};

function searchQuery(item, query) {
  const search = item.searchQuery || item.title.toLowerCase().replace(' ', '');
  return search.includes(query);
}

function Tutorials({ data, location }) {
  const { tutorials } = data.json;
  const [pagination, setPage] = usePagination(tutorials, 6, 'tutorials-page');
  const [search] = useSearch(tutorials, searchQuery, 'tutorials-search');
  return (
    <Page className="tutorials" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row>
            <Col xl={10}>
              <Title subtitle size={5}>
                Game Dev
              </Title>
              <Title nowrap tag="h1">
                Tutorials
              </Title>
              <Text size="lg">
                We release new YouTube videos on a regular basis to teach
                developers how to make complete games from scratch using the
                Unity game engine. Subscribe to our YouTube channel for free if
                you are interested in learning how games are made.
              </Text>
              <SocialButton
                className="margin-top-md margin-bottom-md"
                link="youtube"
                onClick={() => {
                  trackEvent('social_button', {
                    content_type: 'button',
                    event_category: 'engagement',
                    event_label: 'Watch on YouTube',
                  });
                }}
                shape={SocialButton.shape.rounded}
                style={SocialButton.style.outline}
              >
                Watch on YouTube
              </SocialButton>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="margin-top-xxxl" fluid>
        <Row>
          <Col className="margin-bottom-xl" md={6} lg={4}>
            <SearchInput
              {...search}
              aria-label="Search for tutorials"
              onSearch={(query) => {
                search.onSearch(query);
                if (query) {
                  trackEvent('search', {
                    content_type: 'input',
                    event_category: 'engagement',
                    event_label: 'Search for tutorials',
                    search_term: query,
                  });
                }
              }}
            />
          </Col>
          {pagination.paginated && (
            <Col
              className="display-flex flex-row-reverse flex-row-md justify-content-flex-end margin-bottom-xl"
              md={6}
              lg={8}
            >
              <Pagination {...pagination} onPageChange={setPage} />
            </Col>
          )}
        </Row>
        <Row>
          {(search.results || pagination.items).map((tutorial, index) => (
            <Col className="padding-lg" key={tutorial.id} md={6} lg={4}>
              <Thumbnail
                alt={tutorial.title}
                animated
                aria-label={tutorial.title}
                ElementType={GatsbyLink}
                height={tutorial.image.sharp.original.height}
                image={tutorial.image.sharp.original.src}
                index={index}
                onClick={() => {
                  if (typeof sessionStorage !== 'undefined') {
                    sessionStorage.setItem('tutorials-page', pagination.page);
                    sessionStorage.setItem('tutorials-search', search.query);
                  }
                  trackEvent('thumbnail', {
                    content_type: 'image',
                    event_category: 'engagement',
                    event_label: tutorial.title,
                    item_id: tutorial.id,
                  });
                }}
                to={`/tutorials/${tutorial.id}`}
                width={tutorial.image.sharp.original.width}
              />
            </Col>
          ))}
          {search.results && search.results.length === 0 && (
            <Col>
              <Title
                color="foreground-muted"
                marginTop="md"
                size={6}
                weight={500}
              >
                No tutorials were found matching that search.
              </Title>
            </Col>
          )}
        </Row>
      </Container>
    </Page>
  );
}

Tutorials.propTypes = {
  data: PropTypes.shape({
    json: PropTypes.shape({
      tutorials: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          searchQuery: PropTypes.string,
          image: ImageProps,
        })
      ),
    }),
  }),
  location: PropTypes.object,
};

export default Tutorials;
